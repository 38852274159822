import { ArrowBackRounded, ArrowForwardRounded, CloseRounded } from "@mui/icons-material";
import { AppBar, Box, CircularProgress, Grid, IconButton, Modal, Toolbar, Typography, Fade } from "@mui/material";
import { useEffect, useState } from "react";

const LazyImage = ({ src, alt, onClick, isPortrait }) => {
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return (
        <Box
            sx={{
                gridColumn: "span 1",
                gridRow: isPortrait ? "span 2" : "span 1",
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                backgroundColor: "#121212",
                cursor: "pointer",
            }}
            onClick={onClick}
        >
            <Fade in={loaded} timeout={500}>
                <img
                    src={src}
                    alt={alt}
                    onLoad={handleImageLoad}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        opacity: loaded ? 1 : 0,
                        transition: "opacity 0.5s ease-in-out",
                    }}
                />
            </Fade>
            {!loaded && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#121212",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

const AllPhotos = ({ images }) => {
    const [displayedImages, setDisplayedImages] = useState([]);
    const [zoomedImage, setZoomedImage] = useState(null);
    const [loadingZoomed, setLoadingZoomed] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleClose = () => {
        window.history.back();
    };

    const handleImageLoad = () => {
        setLoadingZoomed(false);
    };

    const handleNext = () => {
        if (currentIndex === displayedImages.length - 1) {
            setCurrentIndex(0);
            setZoomedImage(displayedImages[0].src);
            setLoadingZoomed(true);
            return;
        }

        setCurrentIndex(currentIndex + 1);
        setZoomedImage(displayedImages[currentIndex + 1].src);
        setLoadingZoomed(true);
    };

    const handlePrevious = () => {
        if (currentIndex === 0) {
            setCurrentIndex(displayedImages.length - 1);
            setZoomedImage(displayedImages[displayedImages.length - 1].src);
            setLoadingZoomed(true);
            return;
        }

        setCurrentIndex(currentIndex - 1);
        setZoomedImage(displayedImages[currentIndex - 1].src);
        setLoadingZoomed(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowRight") {
            handleNext();
        } else if (event.key === "ArrowLeft") {
            handlePrevious();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    });

    useEffect(() => {
        const processImage = (image) => {
            return new Promise((resolve) => {
                const img = new Image();

                img.onload = () => {
                    resolve({
                        src: image,
                        isPortrait: img.naturalHeight > img.naturalWidth,
                    });
                };

                img.src = image;
            });
        };

        const fetchImages = async () => {
            for (const image of images) {
                const processedImage = await processImage(image); // Remove duplicates
                setDisplayedImages((prev) => {
                    if (prev.find((prevImage) => prevImage.src === processedImage.src)) {
                        return prev;
                    }

                    return [...prev, processedImage];
                });
            }
        };

        fetchImages();
    }, [images]);

    return (
        <Grid container>
            {!window.location.search.includes("noLayout=true") && (
                <Grid item xs={12}>
                    <AppBar
                        position="fixed"
                        sx={{
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            backgroundColor: "transparent",
                            elevation: 0,
                            boxShadow: "none",
                            backgroundImage: "none",
                            display: { xs: "none", lg: "block" },
                        }}
                    >
                        <Toolbar
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "background.default",
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontWeight={800}
                                component="div"
                                sx={{ display: { xs: "none", sm: "block" } }}
                            >
                                Toutes les photos
                            </Typography>

                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                size="large"
                                onClick={() => handleClose()}
                            >
                                <CloseRounded sx={{ fontSize: 40 }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Toolbar sx={{ display: { xs: "none", lg: "block" } }} />
                </Grid>
            )}
            <Grid item xs={12}>
                {displayedImages.length < 4 ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: {
                                xs: "repeat(auto-fit, minmax(300, 1fr))",
                                sm: "repeat(auto-fit, minmax(400px, 1fr))",
                            },
                            gridAutoRows: { xs: "250px", sm: "330px" },
                            gap: "12px",
                            padding: { xs: "6px", sm: "12px" },
                        }}
                    >
                        {displayedImages.map((image, index) => (
                            <LazyImage
                                key={index}
                                src={image.src}
                                alt={`${image.src}`}
                                isPortrait={image.isPortrait}
                                onClick={() => {
                                    setZoomedImage(image.src);
                                    setCurrentIndex(index);
                                    setLoadingZoomed(true);
                                }}
                            />
                        ))}
                    </Box>
                )}
                {displayedImages.length < images.length && displayedImages.length >= 4 && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                        }}
                    >
                        <CircularProgress size={48} sx={{ marginRight: "8px" }} />
                    </Box>
                )}

                <Modal
                    open={!!zoomedImage}
                    onClose={() => setZoomedImage(null)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <Box
                        sx={{
                            position: "relative",
                            maxWidth: "90%",
                            maxHeight: "90%",
                            overflow: "hidden",
                            outline: "none",
                            paddingX: { xs: 0, sm: 8 },
                        }}
                    >
                        {!loadingZoomed && (
                            <IconButton
                                onClick={() => setZoomedImage(null)}
                                sx={{
                                    position: "absolute",
                                    top: "8px",
                                    right: "8px",
                                    zIndex: 1,
                                    backgroundColor: "background.default",
                                }}
                            >
                                <CloseRounded />
                            </IconButton>
                        )}

                        {!loadingZoomed && (
                            <IconButton
                                onClick={handlePrevious}
                                sx={{
                                    position: "absolute",
                                    left: "8px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    zIndex: 1,
                                    backgroundColor: "background.default",
                                }}
                            >
                                <ArrowBackRounded />
                            </IconButton>
                        )}

                        {!loadingZoomed && (
                            <IconButton
                                onClick={handleNext}
                                sx={{
                                    position: "absolute",
                                    right: "8px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    zIndex: 1,
                                    backgroundColor: "background.default",
                                }}
                            >
                                <ArrowForwardRounded />
                            </IconButton>
                        )}

                        {zoomedImage && (
                            <img
                                src={zoomedImage}
                                alt="Zoomed"
                                onLoad={handleImageLoad}
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "calc(100vh - 128px)",
                                    objectFit: "contain",
                                    borderRadius: "8px",
                                    backgroundColor: "background.default",
                                }}
                            />
                        )}

                        {loadingZoomed && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                    </Box>
                </Modal>
                
            </Grid>
        </Grid>
    );
};

export default AllPhotos;
